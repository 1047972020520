import axios, { baseUrl } from '@/config/axios';
import { AxiosResponse } from 'axios';
import { transformError } from '..';
import {
    CreateMenuParams,
    DeleteMenuParams,
    EditMenuParams,
    POSMenusResponse,
    MenuCategoryCreateParams,
    MenuCategoryResponse,
    MenuDetailedResponse,
    MenuProductCreateParams,
    MenuProductDetailedResponse,
    MenuProductResponse,
    MenuResponse,
    MenuTagsCreateParams,
    MenuTagsGetParams,
    MenuTagsResponse,
    POSMenuDetailsResponse,
    MenuProductEditParams,
    ProductTypes,
    ISnoozedProduct,
    ISnoozedPayload,
    CategoryTypes,
    IExportProductPayload,
    IPreviewRes,
} from './types';

class MenuService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new MenuService();
        }

        return this.instance;
    }

    private static instance: MenuService;

    public getMenu(id: string, params?: any) {
        return axios
            .get<AxiosResponse<MenuResponse>>(`/vendor/menu/all/${id}`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getDetailedMenu(restaurantId: string, id: string | string[]) {
        return axios
            .get<AxiosResponse<MenuDetailedResponse>>(`/vendor/restaurant/menu/${restaurantId}/detail/${id}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getSummarizedMenu(restaurantId: string, id: string | string[]) {
        return axios
            .get<AxiosResponse<MenuDetailedResponse>>(`/vendor/restaurant/menu/${restaurantId}/summarize/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenus(restaurantId: string, params?: any) {
        return axios
            .get<AxiosResponse<MenuResponse[]>>(`vendor/restaurant/menu/${restaurantId}`, {
                params: params || {},
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createMenu(restaurantId: string, data: CreateMenuParams) {
        return axios
            .post<AxiosResponse<MenuResponse>>(`/vendor/restaurant/menu/${restaurantId}`, data)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editMenu(restaurantId: string, id: string | string[], data: EditMenuParams) {
        // v2
        return axios
            .put<AxiosResponse<MenuResponse>>(`/vendor/restaurant/menu/${restaurantId}/${id}`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public truncateMenu({ restaurantId, menuIDs }: { restaurantId: string; menuIDs?: string[] }) {
        return axios
            .delete<AxiosResponse>(`/vendor/restaurant/menu/${restaurantId}/truncate`, {
                data: {
                    ...(menuIDs ? { menuIds: menuIDs } : { menuIds: null }),
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenu({ restaurantId, id }: DeleteMenuParams) {
        return axios
            .delete<AxiosResponse>(`/vendor/restaurant/menu/${restaurantId}/${id}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenuCategories(restaurantId: string, menu_id: string | string[] | undefined) {
        return axios
            .get<AxiosResponse<MenuCategoryResponse[]>>(`/vendor/menu/${restaurantId}/category/${menu_id}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenuCategoriesByType(restaurantId: string, type: 'MODIFIER_GROUP' | 'CATEGORY') {
        return axios
            .get<AxiosResponse<MenuCategoryResponse[]>>(`/vendor/menu/${restaurantId}/category/all/${type}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenuCategory(
        type: CategoryTypes,
        restaurantId: string,
        categoryId: string,
        withSnooze?: boolean,
        options?: { signal?: AbortSignal },
    ) {
        return axios
            .get<AxiosResponse<MenuCategoryResponse>>(
                `/vendor/menu/${restaurantId}/category/detail/${categoryId}/${type}`,
                { signal: options?.signal },
            )
            .then((res) => res.data.data)
            .then((res) => {
                if (withSnooze) {
                    const ids = [...res.products, ...res.bundles].map((o) => o.id);
                    return this.getManySnoozedProducts(restaurantId, ids)
                        .then((snoozeRes) => {
                            const snoozeMap = snoozeRes.reduce<{ [key: string]: ISnoozedProduct }>((acc, curr) => {
                                acc[curr.id] = curr;
                                return acc;
                            }, {});
                            return {
                                ...res,
                                products: res.products.map((o) => ({
                                    ...o,
                                    snooze: snoozeMap[o.id],
                                })),
                                bundles: res.bundles.map((o) => ({
                                    ...o,
                                    snooze: snoozeMap[o.id],
                                })),
                            };
                        })
                        .catch(() => {
                            return res;
                        });
                }
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getAllCategories(restaurantId: string) {
        return axios
            .get<AxiosResponse<MenuCategoryResponse[]>>(`/vendor/menu/${restaurantId}/category/all`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getProductWithType(
        restaurantId: string,
        menuId: string,
        type: ProductTypes,
        page: number,
        limit: number,
        search: string,
        withSnooze?: boolean,
        options?: {
            signal?: AbortSignal;
        },
    ) {
        return axios
            .get(`/vendor/menu/${restaurantId}/product/menuId/${menuId}/${type}`, {
                params: {
                    page,
                    limit,
                    search,
                },
                signal: options?.signal,
            })
            .then((res) => res.data)
            .then((res) => {
                if (withSnooze) {
                    const ids = res.data.data.map((o: any) => o.id);
                    return this.getManySnoozedProducts(restaurantId, ids)
                        .then((snoozeRes) => {
                            const snoozeMap = snoozeRes.reduce<{ [key: string]: ISnoozedProduct }>((acc, curr) => {
                                acc[curr.id] = curr;
                                return acc;
                            }, {});
                            return {
                                data: res.data.data.map((o: any) => ({
                                    ...o,
                                    snooze: snoozeMap[o.id],
                                })),
                                count: res.data.count,
                            };
                        })
                        .catch(() => {
                            return res;
                        });
                }
                return res.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategoryWithType(
        restaurantId: string,
        menuId: string,
        type: CategoryTypes,
        page: number,
        limit: number,
        search: string,
        withSnooze?: boolean,
        options?: {
            signal?: AbortSignal;
        },
    ) {
        return axios
            .get(`/vendor/menu/${restaurantId}/category/menuId/${menuId}/${type}`, {
                params: {
                    page,
                    limit,
                    search,
                },
                signal: options?.signal,
            })
            .then((res) => res.data)
            .then((res) => {
                if (withSnooze) {
                    const ids = res.data.data.map((o: any) => o.id);
                    return this.getManySnoozedProducts(restaurantId, ids)
                        .then((snoozeRes) => {
                            const snoozeMap = snoozeRes.reduce<{ [key: string]: ISnoozedProduct }>((acc, curr) => {
                                acc[curr.id] = curr;
                                return acc;
                            }, {});
                            return {
                                data: res.data.data.map((o: any) => ({
                                    ...o,
                                    snooze: snoozeMap[o.id],
                                })),
                                count: res.data.count,
                            };
                        })
                        .catch(() => {
                            return res;
                        });
                }
                return res.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createMenuCategory(restaurantId: string, data: MenuCategoryCreateParams) {
        // v2
        return axios
            .post<AxiosResponse<MenuCategoryResponse>>(`/vendor/menu/${restaurantId}/category/`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public batchCreateMenuCategory(restaurantId: string, menu_id: string, data: MenuCategoryCreateParams[]) {
        // v2
        return axios
            .post<AxiosResponse<MenuCategoryResponse[]>>(
                `/vendor/menu/${restaurantId}/category/${menu_id}/batch`,
                data,
                {
                    baseURL: baseUrl.replace('/v1', '/v2'),
                },
            )
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editMenuCategory(restaurantId: string, category_id: string, data: MenuCategoryCreateParams) {
        // v2
        const d: MenuCategoryCreateParams = {
            ...data,
            productIds: data.productIds.map((pr: any) => {
                if (pr.id) {
                    return pr.id;
                }
                return pr;
            }),
        };
        return axios
            .put<AxiosResponse<MenuCategoryResponse>>(`/vendor/menu/${restaurantId}/category/${category_id}`, d, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenuCategory({ id, restaurantId, menuId }: DeleteMenuParams) {
        // v2
        return axios
            .delete<AxiosResponse>(`/vendor/menu/${restaurantId}/category/${id}/${menuId}`, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public detachMenuCategory({
        restaurantId,
        data,
    }: {
        restaurantId: string;
        data: {
            productId: string;
            categoryId: string;
            menuId: string;
        }[];
    }) {
        // v2
        return axios
            .delete<AxiosResponse>(`/vendor/menu/${restaurantId}/category/detach`, {
                data,
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenuProducts(restaurantId: string, type: string) {
        return axios
            .get<AxiosResponse<MenuProductResponse[]>>(`/vendor/menu/${restaurantId}/product/all/${type}`, {
                params: {
                    table: true,
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategoryProducts(restaurantId: string, category_id: string) {
        return axios
            .get<AxiosResponse<MenuProductResponse[]>>(`/vendor/menu/${restaurantId}/product/${category_id}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategoryProductDetails(type: ProductTypes, restaurantId: string, category_id: string, id: string) {
        return axios
            .get<AxiosResponse<MenuProductDetailedResponse>>(
                `/vendor/menu/${restaurantId}/product/detail/${category_id}/${id}/${type}`,
            )
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getAllProducts(restaurantId: string, type: ProductTypes | 'ALL') {
        return axios
            .get<AxiosResponse<MenuProductDetailedResponse[]>>(`/vendor/menu/${restaurantId}/product/all/${type}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createMenuProduct(restaurantId: string, data: MenuProductCreateParams) {
        // v2
        return axios
            .post<AxiosResponse<MenuProductResponse>>(`/vendor/menu/${restaurantId}/product/`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public batchCreateMenuProduct(restaurantId: string, data: MenuProductCreateParams[]) {
        // v2
        return axios
            .post<AxiosResponse<MenuProductResponse[]>>(`/vendor/menu/${restaurantId}/product/batch`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editMenuProduct(restaurantId: string, data: MenuProductEditParams) {
        // v2
        return axios
            .put<AxiosResponse<MenuProductResponse>>(`/vendor/menu/${restaurantId}/product/${data.id}`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenuProduct({ id, restaurantId, menuId }: DeleteMenuParams) {
        // v2
        return axios
            .delete<AxiosResponse>(`/vendor/menu/${restaurantId}/product/${id}/${menuId}`, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public detachMenuProduct({
        menuId,
        restaurantId,
        data,
    }: {
        menuId: string;
        restaurantId: string;
        data: {
            parentProductId: string;
            childProductId: string;
        }[];
    }) {
        // v2
        return axios
            .delete<AxiosResponse>(`/vendor/menu/${restaurantId}/product/detach/${menuId}`, {
                data,
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getAllTags(data: MenuTagsGetParams) {
        return axios
            .get<AxiosResponse<MenuTagsResponse[]>>(`/vendor/menu/${data.restaurantId}/tag/all`, {
                params: data,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getTags(restaurantId: string, tagIds: string[]) {
        return axios
            .get<AxiosResponse<MenuTagsResponse[]>>(`/vendor/menu/${restaurantId}/tag`, {
                params: {
                    tagIds,
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createMenuTag(data: MenuTagsCreateParams) {
        return axios
            .post<AxiosResponse<MenuTagsResponse>>(`/vendor/menu/tag/create`, data)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenuTag(id: string) {
        return axios
            .delete<AxiosResponse>(`/vendor/menu/tag/delete/${id}`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public publishMenus(restaurantId: string, menuIds: string[], type: 'MENU') {
        return axios
            .put<AxiosResponse>(`/vendor/menu/${restaurantId}/publish/queue/batch/${type}`, {
                menuIds,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getPOSMenus(restaurantId: string, posName: string) {
        return axios
            .get<AxiosResponse<POSMenusResponse>>(`/vendor/qsr/menu/${restaurantId}/${posName}`, {
                params: {
                    filter: 'skip_deleted',
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getPOSMenu(id: string, restaurantId: string, posName: string, file?: FormData, params?: any) {
        return axios
            .post<AxiosResponse<POSMenuDetailsResponse>>(`/vendor/qsr/menu/${restaurantId}/${posName}/${id}`, file, {
                params: params || {},
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public syncMenuServer(restaurantId: string, data: any) {
        return axios
            .post<AxiosResponse<POSMenuDetailsResponse>>(`/vendor/restaurant/menu/${restaurantId}/sync`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getManySnoozedProducts(restaurantId: string, ids: string[]) {
        return axios
            .post<AxiosResponse<Array<ISnoozedProduct>>>(`/vendor/menu/${restaurantId}/inventory/snoozed/many/`, {
                ids,
            })
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setManySnoozedProducts(restaurantId: string, menuId: string, data: ISnoozedPayload) {
        return axios
            .post<AxiosResponse<Array<ISnoozedProduct>>>(
                `/vendor/menu/${restaurantId}/inventory/snoozed/set/${menuId}`,
                data,
                { baseURL: baseUrl.replace('/v1', '/v2') },
            )
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public cloneMenus(
        restaurantId: string,
        data: {
            menuId: string;
            restaurantId: string;
        },
    ) {
        return axios
            .post<AxiosResponse>(`/vendor/restaurant/menu/${restaurantId}/clone`, data)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setCategoryOrders(
        restaurantId: string,
        menuId: string,
        data: {
            [x: string]: string;
        },
    ) {
        // v2
        return axios
            .put<AxiosResponse>(`/vendor/menu/${restaurantId}/category/order/${menuId}`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setMenuOrders(
        restaurantId: string,
        data: {
            sortData: Record<string, number>;
        },
    ) {
        return axios
            .put<AxiosResponse>(`/vendor/restaurant/menu/${restaurantId}/order`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public setProductOrders(
        menuId: string,
        restaurantId: string,
        categoryId: string,
        data: {
            [x: string]: string;
        },
    ) {
        // v2
        return axios
            .put<AxiosResponse>(`/vendor/menu/${restaurantId}/product/order/${categoryId}/${menuId}`, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public exportProductReport(restaurantId: string, params: IExportProductPayload) {
        return axios({
            url: `/vendor/qsr/report/${restaurantId}/product`,
            method: 'GET',
            responseType: 'blob', // important
            params,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public exportOrderReport(restaurantId: string, params: IExportProductPayload) {
        return axios({
            url: `/vendor/qsr/report/${restaurantId}/order-details`,
            method: 'GET',
            responseType: 'blob', // important
            params,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public exportProductDistinctReport(restaurantId: string, params: IExportProductPayload) {
        return axios({
            url: `/vendor/qsr/report/${restaurantId}/product-distinct`,
            method: 'GET',
            responseType: 'blob', // important
            params,
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public uploadCsv(restaurantId: string, categoryId: string, menuId: string, file: FormData) {
        return axios
            .post<any>(
                `/vendor/menu/${restaurantId}/product/categoryId/${categoryId}/parentId/${menuId}/upload`,
                file,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    baseURL: baseUrl.replace('/v1', '/v2'),
                },
            )
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public downloadCsv(restaurantId: string, categoryId: string, menuId: string) {
        return axios({
            url: `/vendor/menu/${restaurantId}/product/categoryId/${categoryId}/parentId/${menuId}/download`,
            method: 'GET',
            responseType: 'blob', // important
        })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public menuBulkAvailabilityChange = ({ restaurantId, ...rest }: any) => {
        return axios.put(
            `vendor/restaurant/menu/${restaurantId}/bulk/update`,
            {
                ...rest,
            },
            {
                baseURL: baseUrl.replace('/v1', '/v2'),
            },
        );
    };

    public productBulkAvailabilityChange = ({ restaurantId, menuId, ...rest }: any) => {
        return axios.put(
            `vendor/menu/${restaurantId}/product/menuId/${menuId}/bulk/update`,
            {
                ...rest,
            },
            {
                baseURL: baseUrl.replace('/v1', '/v2'),
            },
        );
    };

    public getPreviewMenu = (restaurantId: string, menuId: string, mode: 'customer' | 'vendor') => {
        return axios
            .get<AxiosResponse<IPreviewRes>>(`vendor/restaurant/menu/${restaurantId}/preview/${menuId}/${mode}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw transformError(err);
            });
    };

    public getTruncateList = (restaurantId: string) => {
        return axios
            .get(`vendor/menu/${restaurantId}/published`)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    };

    public refreshInventory = (restaurantId: string) => {
        return axios
            .get(`vendor/menu/${restaurantId}/inventory/refresh`)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    };

    public recoverMenu = (restaurantId: string, menuIds: string[]) => {
        return axios
            .post(`/vendor/restaurant/menu/${restaurantId}/recover`, {
                menuIds,
            })
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    };
}

export default MenuService;
